import * as React from "react";
import styled from "styled-components";
import Layout from "../comps/reusable/Layout";
import HeroSection from "../comps/reusable/HeroSection";
import Percentages from "../comps/home/Percentages";
import Info from "../comps/home/Info";
import Slides from "../comps/reusable/Slides";
import Quotes from "../comps/home/Quotes";
import { graphql } from "gatsby";
import NewCarousel from "../comps/reusable/NewCarousel";
import { useEffect } from "react";

const App = () => {
  // Redirects if user comes from Google integration in ATS
  useEffect(() => {
    const integrationUrl = window.location.href;
    // const integrationUrl = 'https://empresas.lapieza.io/?code=4/0AX4XfWgF3jFWKWHycLPJOpQJM_u2Bs_EtXnamMoMFHRWjj5rZKrNLv-LegZQ9HlM-wHR6Q&scope=https://www.googleapis.com/auth/calendar%20https://www.googleapis.com/auth/gmail.send%20https://www.googleapis.com/auth/calendar.events';
    //  "http://localhost:8000/?code=4/0AX4XfWgF3jFWKWHycLPJOpQJM_u2Bs_EtXnamMoMFHRWjj5rZKrNLv-LegZQ9HlM-wHR6Q&scope=https://www.googleapis.com/auth/calendar%20https://www.googleapis.com/auth/gmail.send%20https://www.googleapis.com/auth/calendar.events";
    if (window.location.href.includes("www.googleapis.com")) {
      const atsUrl = integrationUrl.replace(
        // "http://localhost:8000",
        "https://ats-dev.lapieza.io/",
        "https://ats.lapieza.io/"
      );
      window.open(atsUrl, "_self");
    }
  }, []);

  return (
    <Layout>
      <HeroSection
        data={{
          imageName: "homeHead.png",
          hasButtons: true,
        }}
        nameSpace="home"
      />
      <Percentages />
      <Info />
      <Slides
        data={[
          {
            red: "homeRed1",
            title: "homeTitle1",
            desc: "homeDesc1",
            button: "homeButton1",
            image: "slideHome.png",
            to: "/ats",
          },
          {
            red: "homeRed2",
            title: "homeTitle2",
            desc: "homeDesc2",
            button: "homeButton2",
            image: "autohunt2.png",
            to: "/pre-screening",
          },
          {
            red: "homeRed3",
            title: "homeTitle3",
            desc: "homeDesc3",
            button: "homeButton3",
            image: "exec.png",
            to: "/headhunting",
          },
          {
            red: "homeRed4",
            title: "homeTitle4",
            desc: "homeDesc4",
            button: "homeButton4",
            image: "carouDigital2.png",
            to: "/digital-campus",
          },
        ]}
      />
      <NewCarousel />
      <Quotes
        data={[
          {
            quote: "quotesFirst",
            author: "authorFirst",
          },
          {
            quote: "quotesSecond",
            author: "authorSecond",
          },
          {
            quote: "quotesThird",
            author: "authorThird",
          },
          {
            quote: "quotesFourth",
            author: "authorFourth",
          },
        ]}
        nameSpace="home"
      />
    </Layout>
  );
};

export default styled(App)``;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
